<template>
  <template-card
    :name="name"
    preview-width="100px"
    preview-height="100px"
    icon="$paper_rectangle"
    @dragend:item="addItemDrop()"
    @click:item="addItem()" />
</template>
<script>
import paperBuilderToolMixin from '@/mixins/paperBuilderToolMixin'
import { v4 as uuid } from 'uuid'
import TemplateCard from './TemplateCard.vue'

export default {
  components: { TemplateCard },
  mixins: [
    paperBuilderToolMixin
  ],
  methods: {
    addItem () {
      const { left, top } = this.paperBuilderDrop || { left: '20px', top: '20px' }
      this.addPaperBuilderCanvas({
        id: this.paperBuilderId,
        data: {
          id: uuid(),
          type: 'square',
          tag: 'div',
          properties: {
            style: {
              position: 'absolute',
              left,
              top,
              width: '100px',
              height: '100px',
              borderColor: '#000000FF',
              borderStyle: 'solid',
              borderWidth: '0px',
              backgroundColor: '#B4A0E1FF'
            }
          },
          children: []
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
